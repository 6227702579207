import * as React from 'react'
import { Link } from 'gatsby'

const BLink = (props) => {
  let classNames = "text-sky-600 hover:text-sky-700"
  if (props.className) {
    classNames += ` ${props.className}`
  }
  classNames = classNames.trim()
  let propsCopy = {...props}
  delete propsCopy.children
  delete propsCopy.className

  if(props.to.indexOf('http') === 0) {
    delete propsCopy.to
    classNames += " external-link"
    return (
      <a href={props.to} {...propsCopy} target="_blank" rel="noreferrer" className={classNames}>{props.children}</a>
    )
  } else {
    return (
      <Link className={classNames} {...propsCopy}>{props.children}</Link>
    )
  }
}

export { BLink }