import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../hooks/use-site-metadata"

function Seo({ keywords: _keywords, description, lang, meta, metaImage, title }) {
  const siteMetadata = useSiteMetadata()

  const keywords = _keywords || siteMetadata.keywords
  const metaDescription = description || siteMetadata.description
  const image = metaImage || siteMetadata.defaultMetaImage
  const src = `${siteMetadata.siteUrl}${image.src}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: keywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
			].concat(
          [
            {
              property: "og:image",
              content: src,
            },
            {
              property: "og:image:width",
              content: image.width,
            },
            {
              property: "og:image:height",
              content: image.height,
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
            {
              name: "twitter:image:src",
              content: src,
            },
            {
              name: "twitter:image",
              content: src,
            },
          ]
        )
        .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}

export default Seo