import * as React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import { GithubIcon, TwitterIcon, EmailIcon  } from './icons'
import { BLink } from '../components/content-components'
import { MDXProvider } from '@mdx-js/react'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Seo from '../components/seo'

const Layout = ({ className, pageTitle, children, keywords = false, description = false, metaImage = false }) => {
  const q = useSiteMetadata()
	const getYear = () => new Date().getFullYear()

  const links = [['home', '/'], ['teaching', '/teaching'], ['name', '/name']]
  const seoTitle = pageTitle ? pageTitle : q.title
  const shortcodes = { Link, a: (props) => <BLink {...props} to={props.href} />, BLink }
  return (
    <div className={"container mx-auto px-8 md:px-0 " + className}>
      <Helmet
        title={pageTitle}
        defaultTitle={q.title}
        titleTemplate={`%s - ${q.title}`}
      />
      <Seo 
        title={seoTitle} 
        metaImage={metaImage} 
        keywords={keywords} 
        description={description} 
      />
      <div className="border-b-2 pb-2 grid md:grid-flow-col md:gap-10 md:auto-cols-max md:place-content-between items-end mb-5">
        <div className="max-w-xs">
          <Link to="/" className="text-sky-500 hover:text-sky-600 font-semibold text-2xl md:text-4xl sourcecodepro">
            {q.navText}
          </Link>
        </div>
        <div className="grid grid-flow-col auto-cols-max gap-3 md:gap-5 sourcecodepro text-lg md:text-xl md:pr-5 md:items-end">
          {links.map((m) => <Link key={m[1]} partiallyActive={m[1] !== '/'} activeClassName="active-nav-link" className="text-zinc-500 hover:text-zinc-900" to={m[1]}>{m[0]}</Link>)}
        </div>
      </div>
      <div>
        <MDXProvider components={shortcodes}>
          {children}
        </MDXProvider>
      </div>
      <div className="sourcecodepro mt-16 text-center md:mt-20 mb-10 text-sm md:text-base text-gray-400">
        <div className="grid grid-flow-col auto-cols-mx gap-2 place-content-center">
          <a className="text-zinc-400 hover:text-zinc-500" href={`mailto:${q.email}`}>
            <EmailIcon className="w-8 h-8 "></EmailIcon>
          </a>
          <a className="text-zinc-400 hover:text-zinc-500" href={q.twitter}>
            <TwitterIcon className="w-8 h-8 "></TwitterIcon>
          </a>
          <a className="text-zinc-400 hover:text-zinc-500" href={q.github}>
            <GithubIcon className="w-8 h-8 "></GithubIcon>
          </a>
        </div>
        
        <div>© 2022-{getYear()} | {q.author}</div>
      </div>
    </div>
  )
}
export default Layout